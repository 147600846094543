/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/astronaut.glb");
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    let danceMove = Object.values(actions)[0];
    danceMove.play();
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, -1, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.015}>
        <primitive object={nodes.mixamorig1Hips} />
        <skinnedMesh
          geometry={nodes.Cube004.geometry}
          material={nodes.Cube004.material}
          skeleton={nodes.Cube004.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Cube004_1.geometry}
          material={materials.red}
          skeleton={nodes.Cube004_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Icosphere_1.geometry}
          material={nodes.Icosphere_1.material}
          skeleton={nodes.Icosphere_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Icosphere_2.geometry}
          material={materials.visor}
          skeleton={nodes.Icosphere_2.skeleton}
        />
      </group>
      <mesh rotation={[Math.PI * 1.58, 0, 0]} position={[0, -1.15, -2]}>
        <planeGeometry args={[5, 5]} />
        <meshBasicMaterial color="red" />
      </mesh>
    </group>
  );
}

useGLTF.preload("/astronaut.glb");

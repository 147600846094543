/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function TowerModel({ ...props }) {
  const group = useRef();
  const [sine, setSine] = useState(0);

  useFrame(({ clock }) => {
    const a = Math.sin(clock.getElapsedTime()) * 0.5 + 0.5;
    setSine(a);
  });

  const { nodes } = useGLTF("/radioTower.glb");
  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      scale={0.25}
      position={[0, -1.5, 0]}
      rotation={[0, Math.PI * 0.25, 0]}
    >
      <mesh
        geometry={nodes.Cube.geometry}
        material={nodes.Cube.material}
        position={[0, 10.15, 0]}
        rotation={[-2.05, -0.48, 3.11]}
        scale={[0.23, 0.23, 0.23]}
      />
      <mesh
        geometry={nodes.Cube001.geometry}
        material={nodes.Cube001.material}
        position={[0, 10.15, 0]}
        rotation={[-1.03, -0.42, -1.33]}
        scale={[0.23, 0.23, 0.23]}
      />
      <mesh
        geometry={nodes.Cube002.geometry}
        material={nodes.Cube002.material}
        position={[0, 10.15, 0]}
        rotation={[-1.11, 0.46, 0.01]}
        scale={[0.23, 0.23, 0.23]}
      />
      <mesh
        geometry={nodes.Cube003.geometry}
        material={nodes.Cube003.material}
        position={[0, 10.15, 0]}
        rotation={[-1.1, 0.18, -1.58]}
        scale={[0.23, 0.23, 0.23]}
      />
      <mesh
        geometry={nodes.Cube004.geometry}
        material={nodes.Cube004.material}
        position={[0.02, 10, -0.02]}
        rotation={[0, 0.73, 0]}
        scale={[0.49, -0.13, 0.57]}
      />
      <mesh
        geometry={nodes.Cylinder.geometry}
        material={nodes.Cylinder.material}
        position={[0, 11.4, -0.01]}
        scale={[0.09, 1.37, 0.09]}
      />
      <mesh
        geometry={nodes.Sphere.geometry}
        material={nodes.Sphere.material}
        position={[0, 12.85, 0]}
        scale={0.21}
      />
      <mesh
        geometry={nodes.Cube008.geometry}
        material={nodes.Cube008.material}
        position={[0.09, 6.59, 0.02]}
        rotation={[0, -0.8, 0]}
        scale={[0.96, -0.15, 0.96]}
      />
      <mesh
        geometry={nodes.Cube009.geometry}
        material={nodes.Cube009.material}
        position={[0.09, 7.91, 0.02]}
        rotation={[0, -0.8, 0]}
        scale={[-0.65, -0.18, -0.65]}
      />
      <mesh
        geometry={nodes.Cube007.geometry}
        material={nodes.Cube007.material}
        position={[0.09, 4.92, 0.02]}
        rotation={[0, -0.8, 0]}
        scale={[1.31, -0.2, 1.31]}
      />
      <mesh
        geometry={nodes.Cube010.geometry}
        material={nodes.Cube010.material}
        position={[0.09, 3.24, 0.02]}
        rotation={[0, -0.8, 0]}
        scale={[1.72, -0.2, 1.72]}
      />
      <mesh
        geometry={nodes.Cube006.geometry}
        material={nodes.Cube006.material}
        position={[0.09, 1.61, 0.02]}
        rotation={[0, -0.8, 0]}
        scale={[2.03, -0.17, 2.03]}
      />
      <mesh
        geometry={nodes.Cube005.geometry}
        material={nodes.Cube005.material}
        position={[0.13, 0.28, -3.08]}
        rotation={[0, 0.73, 0]}
        scale={[0.49, -0.28, 0.57]}
      />
      <mesh
        geometry={nodes.Cube011.geometry}
        material={nodes.Cube011.material}
        position={[3.18, 0.28, -0.09]}
        rotation={[0, 0.73, 0]}
        scale={[0.49, -0.28, 0.57]}
      />
      <mesh
        geometry={nodes.Cube012.geometry}
        material={nodes.Cube012.material}
        position={[0.07, 0.28, 3.04]}
        rotation={[0, 0.73, 0]}
        scale={[0.49, -0.28, 0.57]}
      />
      <mesh
        geometry={nodes.Cube013.geometry}
        material={nodes.Cube013.material}
        position={[-3.1, 0.28, 0.03]}
        rotation={[0, 0.73, 0]}
        scale={[0.49, -0.28, 0.57]}
      />
      <pointLight color={"red"} position={[0, 12.85, 0]} intensity={sine * 2} />
      <mesh position={[0, 13, 0]} scale={0.55}>
        <sphereGeometry />
        <meshBasicMaterial
          color={`rgb(${Math.floor(sine * 255)}, 0, 0)`}
          transparent
          opacity={0.8}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/radioTower.glb");
